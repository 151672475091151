
.footer {
  padding: 50px 0;
  h2 {
    font-size: 28px;
    //text-transform: capitalize;
    font-weight: 600;
    font-family: mono-medium, "sans-serif" !important;
    opacity: 1;
  }
  p{
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    font-family: roboto, "sans-serif" !important;
    opacity: 1;
  }
  .privacy{
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 400;
    font-family: roboto, "sans-serif" !important;
    opacity: 1;
    color: #ffffff;
    cursor: pointer;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 70px 0;
    h2{
      font-size: 22px !important;
    }
  }
}