@import "assets/scss/pages/home-page";
@import "assets/scss/layout/footer";
@import "assets/scss/layout/header";
@import "assets/scss/layout/_loader";
@import "assets/scss/pages/about-page";
@import "assets/scss/pages/page-not-found";
@import "assets/scss/pages/try-beta-page";
@import "assets/scss/pages/privacy-policy";

/*Fonts*/
@font-face {
  font-family: 'roboto-light';
  src: url('assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'roboto';
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'roboto-medium';
  src: local('OpenSans'), url('assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'roboto-bold';
  src: local('OpenSans'), url('assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'mono';
  src: url('assets/fonts/IBMPlexMono/IBMPlexMono-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'mono-medium';
  src: local('OpenSans'), url('assets/fonts/IBMPlexMono/IBMPlexMono-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'mono-bold';
  src: local('OpenSans'), url('assets/fonts/IBMPlexMono/IBMPlexMono-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'caveat';
  src: local('OpenSans'), url('assets/fonts/Caveat_Brush/CaveatBrush-Regular.ttf') format('truetype');
  font-weight: 700;
}


html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

.graph-bg-4-inner::-webkit-scrollbar {
  width: 4px;
  background: red;
}

body::-webkit-scrollbar {
  width: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: linear-gradient(180deg, #779b70 -7.76%, #4f674a 29.4%, #1c4204 59.18%, #35674f 82.76%, #21597A 100%);
}

.graph-bg-4-inner::-webkit-scrollbar-track {
  background: #b7b6b6;
}

body::-webkit-scrollbar-track {
  background: #494848;
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #779b70 -7.76%, #4f674a 29.4%, #1c4204 59.18%, #35674f 82.76%, #21597A 100%);
}

.graph-bg-4-inner::-webkit-scrollbar-thumb {
  background: #949393;
}

/*Common Styling*/

.primary-btn {
  background: #F2EA779D;
  padding: 7px 20px;
  outline: none;
  border: 1px solid #707070;
  border-radius: 28px;
  font-family: mono-medium, "sans-serif";
  font-size: 34px;
  z-index: 1;

  &:hover {
    box-shadow: 2px 2px 18px #F2EA77;
  }
}

.disabled-primary-dark-btn {
  background: #b9b9b9;
  padding: 7px 20px;
  outline: none;
  color: #4b4a4a;
  border: 1px solid #696969;
  font-family: mono-medium, "sans-serif";
  font-size: 18px;
  z-index: 1;
}

.secondary-light-btn {
  background: #FFFFFF;
  padding: 7px 20px;
  outline: none;
  color: #668EF2;
  border: none;
  font-family: mono-medium, "sans-serif";
  font-size: 18px;
  z-index: 1;

  &:hover {
    box-shadow: 2px 2px 18px #c0bfbf;
  }
}

.disabled-primary-dark-btn {
  background: #b9b9b9;
  padding: 7px 20px;
  outline: none;
  color: #4b4a4a;
  border: 1px solid #696969;
  font-family: mono-medium, "sans-serif";
  font-size: 18px;
  z-index: 1;
}

.primary-dark-btn {
  background: #000000;
  padding: 7px 20px;
  outline: none;
  color: #ffffff;
  border: 1px solid #707070;
  font-family: mono-medium, "sans-serif";
  font-size: 18px;
  z-index: 1;

  &:hover {
    box-shadow: 2px 2px 18px #7e7d7d;
  }
}

.main-heading {
  font-size: 60px;
  color: #FFFFFF;
  letter-spacing: 0.96px;
  font-family: mono, "sans-serif";
  opacity: 1;
}

.para {
  font-size: 21px;
  //text-transform: capitalize;
  font-family: roboto, "sans-serif";
  opacity: 1;
  letter-spacing: 0;
}

.h2-heading {
  font-size: 50px;
  color: #826C89;
  letter-spacing: 2.18px;
  font-family: mono-bold, "sans-serif";
  opacity: 1;
}

@media screen and (max-width: 1600px) {
  .primary-btn {
    font-size: 24px;
  }
  .h2-heading {
    font-size: 40px !important;
  }
  .main-heading {
    font-size: 45px;
  }
  .para {
    font-size: 21px;
  }
}

@media screen and (max-width: 1024px) {
  .primary-btn {
    font-size: 22px;
  }
  .main-heading {
    font-size: 34px;
  }
  .para {
    font-size: 19px;
  }
  .h2-heading {
    font-size: 35px !important;
  }
}

@media screen and (max-width: 560px) {
  .main-heading {
    font-size: 30px !important;
  }
  .h2-heading {
    font-size: 26px !important;
  }
}

.Toastify__toast--error {
  background: #FAE1E2 !important;
}

.Toastify__toast--success {
  background: #e1fae5 !important;
}

.container{
  max-width: 1700px !important;
  margin: 0 auto;
  width: 100%;
  padding: 0 40px !important;
  @media screen and (max-width: 900px){
    padding: 0 15px !important;
  }
}

.modal-content{
  width: unset !important;
}