/**********************Hero Section***********************/
.hero-section {
  background: linear-gradient(180deg, #7F9BB0 -7.76%, #B5B6BB 29.4%, #BC9F9B 59.18%, #A07B8C 82.76%, #21597A 100%);
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 100px !important;
  align-items: center;
  position: relative;

  .hero-left {
    .para {
      color: #FFFFFF;
    }
  }
}

@keyframes animated-text{
  from{width: 0;}
  to{width: 100%;}
}

/****************************About Section*******************************/
.about-section {
  height: 100%;
  display: flex;
  align-items: center;

  .about-left {
    span {
      font-size: 20px;
      color: #826C89;
      letter-spacing: 2.18px;
      font-weight: 600;
      font-family: Work Sans, "sans-serif";
      opacity: 1;
    }

    .h2-heading {
      color: #826C89;
    }

    .para {
      color: #000000BC;
    }
  }
}

@media screen and (min-width: 1200px) {
  .hero-section {
    min-height: 100vh;
  }
  .about-section {
    min-height: 80vh;
  }
}

@media screen and (max-width: 1600px) {
  .hero-section img {
    width: 350px !important;
  }
}

@media screen and (max-width: 1024px) {
  .hero-section img {
    width: 270px !important;
  }
}

@media screen and (max-width: 560px) {
  .hero-section {
    padding-top: 80px !important;
  }
  .hero-section img {
    width: 150px !important;
  }
  .hero-section .section, .about-section .section {
    padding: 0 20px;
  }
  .hero-section .hero-right img {
    width: 200px;
  }
}