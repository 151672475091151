  .page-not-found{
  background: linear-gradient(180deg, #7F9BB0 -7.76%, #B5B6BB 29.4%, #BC9F9B 59.18%, #A07B8C 82.76%, #21597A 100%);
  height: 100vh;
  font-family: roboto-bold, "sans-serif";
  h3{
    color: #fff;
    font-size: 120px;
  }
  h2{
    color: #fff;
  }
}