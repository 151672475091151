.about {
  background: #99B1BF1C 0% 0% no-repeat padding-box;
  opacity: 1;

  .about-info {
    padding: 130px 0 90px 0;
    //box-shadow: 0px 15px 20px #00000052;
    .roles {
      @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        justify-content: space-around !important;
      }

      img {
        width: 200px;
        height: 140px;
        @media screen and (max-width: 1024px) {
          width: 300px;
          height: 200px;
        }
      }

      h4 {
        font-size: 30px;
        font-family: roboto-bold, sans-serif !important;
        @media screen and (max-width: 1024px) {
          font-size: 24px;
        }
      }

      .h2-heading {
        font-family: mono-medium, sans-serif !important;
      }
    }

  }

  .try-beta {
    background: #99AFF21C;
    //box-shadow: 0 15px 20px #00000052;
    opacity: 1;
    padding: 90px 0;
    position: relative;

    .connect-absolute {
      position: absolute;
      top: -90px;
      text-align: center;
      left: 50%;
      transform: translate(-50%);
      @media screen and (max-width: 1300px) {
        top: -60px;
      }
      @media screen and (max-width: 767px) {
        top: -30px;
        img {
          width: 100px;
        }
      }
      p {
        font-family: caveat, sans-serif;
        font-size: 40px;
        @media screen and (max-width: 1600px) {
          font-size: 35px;
        }
        @media screen and (max-width: 1300px) {
          font-size: 25px;
        }
        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }
    }

    .analyze-calender {
      color: #000000;
      font-size: 30px;
      font-family: mono, sans-serif !important;
      font-weight: normal !important;
      @media screen and (max-width: 1024px) {
        font-size: 28px !important;
      }

      @media screen and (max-width: 560px) {
        font-size: 20px !important;
      }
    }

    p {
      font-size: 28px;

      b {
        font-family: roboto-bold, sans-serif !important;
      }

      @media screen and (max-width: 1024px) {
        font-size: 22px !important;
      }
      @media screen and (max-width: 768px) {
        font-size: 18px !important;
      }
    }

    .h2-heading {
      color: #000000;
      font-size: 38px;
      font-family: mono-medium, sans-serif !important;
      @media screen and (max-width: 1024px) {
        font-size: 30px !important;
      }

      @media screen and (max-width: 560px) {
        font-size: 22px !important;
      }
    }
  }

  .our-team {
    padding: 90px 0;

    .member-name {
      font-size: 22px;
      letter-spacing: 0.99px;
      color: #000000;
      opacity: 1;
      font-family: roboto-medium, "sans-serif";
    }

    .member-designation {
      font-size: 18px;
      letter-spacing: 0.82px;
      color: #000000;
      font-family: roboto, "sans-serif";
      opacity: 1;
    }

    .member-email {
      font-size: 16px;
      letter-spacing: 0.82px;
      color: #000000;
      font-family: roboto, "sans-serif";
      opacity: 1;
    }
  }

  .h2-heading {
    color: #000000;
    font-family: mono-medium, "sans-serif";
  }

  .para {
    letter-spacing: 0.99px;
    color: #000000;
    opacity: 1;
    text-transform: none;
  }
}

@media screen and (max-width: 1024px) {
  .our-team {
    .member-name {
      font-size: 18px !important;
    }

    .member-designation {
      font-size: 16px !important;
    }

    .member-email {
      font-size: 14px !important;
    }
  }
}

@media screen and (max-width: 560px) {
  .about-info {
    padding: 120px 10px 30px 10px !important;
  }

  .try-beta {
    position: relative;
    padding: 50px 10px !important;
  }
  .our-team {
    padding: 50px 10px !important;
  }
}

@media screen and (max-width: 539px) {
  .about-info img {
    width: 60px;
  }
}

.content {
  position: relative;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  border-radius: 30px;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-image {
  width: 100%;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.fadeIn-bottom {
  top: 80%;
}

