.main-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.hamburger .line{
  width: 30px;
  height: 4px;
  background-color: #ecf0f1;
  display: block;
  margin: 7px auto;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}

#hamburger-1.is-active .line:nth-child(2){
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1){
  -webkit-transform: translateY(10px) rotate(45deg);
  -ms-transform: translateY(10px) rotate(45deg);
  -o-transform: translateY(10px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3){
  -webkit-transform: translateY(-12px) rotate(-45deg);
  -ms-transform: translateY(-12px) rotate(-45deg);
  -o-transform: translateY(-12px) rotate(-45deg);
  transform: translateY(-12px) rotate(-45deg);
}


/*On Scroll changes*/
.nav-linksBlack {
  font-size: 16px;
  color: #000 !important;
  text-align: center;
  letter-spacing: 0.96px;
  text-transform: capitalize;
  font-family: roboto-medium, "sans-serif";
  opacity: 1;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  &:hover {
    color: #3FBF6C !important;
  }
}

/*Primary Navbar*/

.navbar {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
    z-index: 20;
    opacity: 1;
    position: fixed !important;
    right: 0;
    top: 0;

  .nav-container {
    //padding: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 80px;

    .nav-menu {
      display: flex;
      list-style: none;
      text-align: center;
      margin-right: 2rem;

      .nav-item {
        line-height: 40px;
        .nav-links,.nav-linksBlack {
          font-size: 16px;
          color: #fff;
          text-align: center;
          letter-spacing: 0.96px;
          text-transform: capitalize;
          font-family: roboto-medium, "sans-serif";
          opacity: 1;
          text-decoration: none;
          padding: 0.5rem 1rem;
          height: 100%;
          &:hover {
            color: #3FBF6C;
          }
        }
      }
      .nav-item .active{
        color: #3FBF6C !important;
      }

      .nav-item:after {
        content: "";
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 4px;
        width: 0;
        background: transparent;
        transition: width 0.7s ease, background-color 0.5s ease;
      }
      .nav-item:hover:after {
        width: 40% !important;
      }
    }
  }
}

/*Navbar for other pages*/
.shadow-bg {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 5px #00000029;
}

.bg-white{
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 5px #00000029;
  opacity: 1;
  .nav-container{
    .nav-menu{
      .nav-item{
        .nav-links{
          color: #000 !important;
          &:hover{
              color: #3FBF6C !important;
          }
        }
        &:after {
          content: "";
          display: block;
          height: 3px;
          width: 0;
          background: transparent;
          transition: width 0.7s ease, background-color 0.5s ease;
        }
        &:hover:after {
          width: 100%;
          background: #3FBF6C !important;
        }
      }
      .nav-item .active {
        color: #3FBF6C !important;
      }
    }
  }
}

.logo-wrapper {
  align-items: center;
  margin-top: -18px;
  //margin-left: 50px;
  flex-grow: 1;
  .nav-logo {
    color: #000000;
    text-decoration: none;
    font-size: 35px;
    font-family: mono, "sans-serif";
    font-weight: 400;
    flex-grow: 1;
  }
  img{
    width: 35px;
    height: 42px;
    object-fit: cover;
  }
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 1600px) {
  .nav-links {
    font-size: 16px !important;
  }
  .nav-linksBlack{
    font-size: 16px !important;
  }
}

@media screen and (max-width: 960px) {
  .navbar {
    left: 0;
  }

  .logo-wrapper {
    //margin-left: -15px;
    .nav-logo{
      font-size: 28px;
    }
  }

  .shadow-bg {
    background: transparent !important;
    box-shadow: none;
  }

  .bg-white {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 5px #00000029;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 0;
    padding-top: 100px;
    padding-left: 0;
    left: -110%;
    height: 100vh;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #f3f3f3;
    left: 0px;
    position: fixed;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .navbar .nav-container {
    .nav-menu {
      .nav-item {
        line-height: 40px;
        //margin-right: 1rem;

        .nav-linksBlack, .nav-links {
          font-size: 16px;
          color: #1f1f1f !important;
          text-align: center;
          letter-spacing: 0.96px;
          text-transform: capitalize;
          font-family: roboto-medium, "sans-serif";
          opacity: 1;
          text-decoration: none;
          padding: 0.5rem 1rem;
          height: 100%;
          &:hover {
            color: #3FBF6C !important;
          }
        }
      }
      .nav-item .active {
        color: #3FBF6C !important;
      }
      .nav-item:after {
        content: "";
        display: block;
        height: 4px;
        width: 0;
        background: transparent;
        transition: width 0.7s ease, background-color 0.5s ease;
      }
    }
  }

  .nav-icon {
    display: block;
    position: fixed;
    top: -4px;
    z-index: 1;
    right: -22px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #152636;
    text-align: center;
    width: 43px;
    background-color: #7eb491;
  }

}