.privacy-policy{
  padding: 80px 0 0 0;
  background: #F9F9F9;
  @media screen and (max-width: 1024px) {
    padding: 120px 0 0 0;
  }
  @media screen and (max-width: 768px) {
    padding: 90px 0 0 0;
  }
  h1{
    text-align: center;
    font-size: 30px;
    font-family: roboto-bold, "sans-serif";
    letter-spacing: 1.32px;
    color: #3FBF6C;
  }
  h2{
    font-size: 20px;
    font-family: roboto-bold, "sans-serif";
    letter-spacing: 1.32px;
    color: #000000;
  }
  h3{
    text-transform: capitalize;
    font-size: 18px;
    font-family: roboto-medium, "sans-serif";
    letter-spacing: 1.32px;
    color: #000000;
  }
  li, p{
    font-size: 16px;
    font-family: roboto-light, "sans-serif";
    letter-spacing: 1.32px;
    color: #000000;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  a, span{
    display: inline-block;
    line-break: anywhere;
    font-size: 16px;
    font-family: roboto, "sans-serif";
    letter-spacing: 1.32px;
    color: #000000;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  table{
    font-family: roboto-light, "sans-serif";
  }
  .cursor{
    cursor: pointer;
    text-decoration: underline;
  }
  .cursor:hover{
    color: blue;
  }
}