.loader {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
  display: flex;
  .loader-component{
    border-radius: 50%;
    position: relative;
    width: 180px;
    height: 180px;

    &:after, &:before {
      position: absolute;
      content: "";
    }

    &:after {
      height: 10px;
      width: 100px;
      background-color: #ffffff;
      border-radius: 5px;
      top: 110px;
      left: 40px;
      animation: loader-swing 0.8s ease-in-out infinite;
      animation-direction: alternate;
    }

    &:before {
      height: 20px;
      width: 20px;
      background-color: #A37EF2;
      border-radius: 50%;
      top: 30px;
      left: 78px;
      animation: loader-bounce 0.4s ease-in-out infinite;
      animation-direction: alternate;
    }
  }

  img {
    margin-bottom: 50px;
  }

  ul {
    display: flex;
  }

  ul li {
    list-style: none;
    height: 20px;
    width: 20px;
    margin: 0 4px;
    border-radius: 50%;
    animation: bounce 1s linear infinite;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-40px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  ul li:nth-child(1) {
    animation-delay: 0.2s;
    background-color: #A37EF2;
  }

  ul li:nth-child(2) {
    animation-delay: 0.6s;
    background-color: #ffffff;
  }

  ul li:nth-child(3) {
    animation-delay: 0.1s;
    background-color: #A37EF2;
  }

  ul li:nth-child(4) {
    animation-delay: 0.8s;
    background-color: #ffffff;
  }

  ul li:nth-child(5) {
    animation-delay: 0.5s;
    background-color: #A37EF2;
  }

  ul li:nth-child(6) {
    animation-delay: 0.1s;
    background-color: #ffffff;
  }
}

@keyframes loader-bounce {
  0% {
    height: 24px;
    transform: translateY(0px);
  }
  75%{
    height: 20px;
    width: 20px;
  }
  100% {
    height: 10px;
    width: 24px;
    transform: translateY(74px);
  }
}

@keyframes loader-swing {
  0% {
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(45deg);
  }
}
