.trybeta {
  padding: 80px 0 0 0;
  background: #F9F9F9;
  @media screen and (max-width: 1024px) {
    padding: 120px 0 0 0;
  }
  @media screen and (max-width: 768px) {
    padding: 90px 0 0 0;
  }

  #graph-section {
    padding: 40px 120px;
    @media screen and (max-width: 1600px) {
      padding: 30px 50px;
    }
    @media screen and (max-width: 768px) {
      padding: 20px 15px;
    }

    .graphs {
      position: relative;
      background: #fff;
      padding: 50px 70px;
      @media screen and (max-width: 1500px) {
        padding: 40px;
      }
      @media screen and (max-width: 767px) {
        padding: 30px 20px;
      }
      border-radius: 50px;

      .tip {
        position: absolute;
        right: 35px;
        cursor: pointer;
        top: 15px;
        @media screen and (max-width: 540px) {
          right: 15px;
        }

        p {
          font-size: 14px;
          border-bottom: 2px solid #F2EA77;
          letter-spacing: 0.99px;
          color: #2a2a2a;
          opacity: 1;
          font-family: roboto-medium, "sans-serif";
          @media screen and (max-width: 540px) {
            font-size: 10px;
          }
        }

        img {
          @media screen and (max-width: 540px) {
            width: 30px;
          }
        }
      }

      .graphs-container {
        .graphs-group {
          width: 50%;
          @media screen and (max-width: 776px) {
            width: 100%;
          }
        }

        @media screen and (max-width: 1380px) {
          .graph-bg-4 {
            width: 300px;
            //height: 300px !important;
          }
        }
        @media screen and (max-width: 480px) {
          .graph-bg-4 {
            //height: auto !important;
          }
        }

        .graph-bg {
          width: 300px;
          height: 320px;
          min-height: 250px;
          @media screen and (max-width: 1380px) {
            width: 400px;
            height: 400px;
          }
          @media screen and (max-width: 1023px) {
            width: 300px;
            height: 300px;
          }
          @media screen and (max-width: 480px) {
            height: auto;
            width: 100%;
          }
          background: #fff;
          box-shadow: 0px 3px 40px 0px #e3eaed;
          padding: 20px;
          border-radius: 40px;

          .graph-head {
            color: #4f4e4e;
            font-size: 14px;
            font-family: roboto-bold, "sans-serif";
          }

          .graph-month {
            color: #3f3e3e;
            font-size: 13px;
            font-family: roboto-medium, "sans-serif";
          }

          .graph-4 {
            .color {
              width: 30px;
              height: 30px;
              margin-right: 10px;
              border-radius: 100px;
              min-width: 30px;
              min-height: 30px;
            }

            .name-email {
              width: 40%;
              @media screen and (max-width: 480px) {
                width: 40%;
              }

              h6 {
                font-size: 13px;
                letter-spacing: 0.99px;
                color: #262626;
                opacity: 1;
                font-family: roboto-medium, "sans-serif";
              }

              .email {
                font-size: 13.5px;
                letter-spacing: 0.99px;
                color: #5d5c5c;
                opacity: 1;
                cursor: default !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: roboto, "sans-serif";
              }
            }

            .time {
              font-size: 13px;
              width: 40%;
              letter-spacing: 0.99px;
              color: #5b5a5a;
              opacity: 1;
              margin-left: 10px !important;
              font-family: roboto-medium, "sans-serif";
              @media screen and (max-width: 480px) {
                width: 32%;
              }
            }
          }
        }

        .graph-bg-4 {
          .graph-bg-4-inner {
            overflow-y: scroll;
            height: calc(100% - 10px);
            padding-right: 10px;
          }

          @media screen and (max-width: 480px) {
            height: 320px !important;
            width: 100%;
          }
        }

        .graph-2 {
          .day {
            width: 15% !important;
            font-size: 13px;
            letter-spacing: 0.99px;
            color: #3b3b3b;
            opacity: 1;
            font-family: roboto-medium, "sans-serif";
          }

          .hours {
            width: 40% !important;
            font-size: 13px;
            letter-spacing: 0.99px;
            color: #5b5a5a;
            opacity: 1;
            font-family: roboto-medium, "sans-serif";
            @media screen and (max-width: 360px) {
              width: 32% !important;
            }
          }

          .progress-container {
            width: 40% !important;

            .progress {
              height: 8px;
              border-radius: 0;

              .progress-bar {
                animation-name: animateBar;
                animation-iteration-count: 1;
                animation-timing-function: ease-in;
                animation-duration: .4s;

              }
            }
          }

          @keyframes animateBar {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }
        }
      }

      .graph-container-2 {
        justify-content: center;
        @media screen and (max-width: 1380px) {
          justify-content: space-around;
        }
        .click_here{
          color: #3f3e3e;
          font-size: 16px;
          font-family: roboto, "sans-serif";
          a{
            color: #668EF2;
          }
        }

        .graph-head {
          color: #3f3e3e;
          font-size: 14px;
          font-family: roboto-bold, "sans-serif";
        }

        .graph-sub-head {
          color: #575555;
          font-size: 13px;
          font-family: roboto, "sans-serif";
        }

        .role-details {
          width: 400px;
          @media screen and (max-width: 1380px) {
            width: 400px;
            height: auto;
          }
          @media screen and (max-width: 1023px) {
            width: 300px !important;
            height: auto;
            display: grid;
          }
          height: auto;
          background: #fff;
          Box-shadow: 0px 3px 40px 0px #e3eaed;
          padding: 20px;
          border-radius: 40px;
          @media screen and (max-width: 480px) {
            width: 100% !important;
          }

          h2 {
            font-size: 21px;
            color: #8893FB;
            font-family: roboto-medium, "sans-serif";
            @media screen and (max-width: 1600px) {
              font-size: 21px !important;
            }

            @media screen and (max-width: 1024px) {
              font-size: 20px !important;
            }

            @media screen and (max-width: 560px) {
              font-size: 17px !important;
            }
          }

          .para {
            font-size: 16px;
            @media screen and (max-width: 776px) {
              font-size: 14px;
            }
          }
        }

        .optimize-result {
          position: relative;
          width: 250px;
          @media screen and (max-width: 1380px) {
            width: 400px;
            height: auto;
          }
          @media screen and (max-width: 1023px) {
            width: 300px !important;
            height: auto;
            display: grid;
          }
          height: 100%;
          background: #fff;
          Box-shadow: 0px 3px 40px 0px #e3eaed;
          padding: 20px;
          border-radius: 40px;
          @media screen and (max-width: 480px) {
            width: 100% !important;
          }

          .icon {
            position: absolute;
            right: 15px;
            bottom: 15px;
          }

          .circular-graph {
            position: relative;

            .circular-graph-text {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              letter-spacing: 0.99px;
              color: #000000;
              font-size: 20px;
              font-family: roboto-medium, "sans-serif";
            }
          }
        }

        .schedule-optimization {
          position: relative;
          width: 500px;
          min-height: 200px;
          @media screen and (max-width: 1380px) {
            width: 400px;
            height: auto;
            margin-left: 0;
          }
          @media screen and (max-width: 1023px) {
            width: 300px;
            height: auto;
          }
          height: 100%;
          background: #fff;
          Box-shadow: 0px 3px 40px 0px #e3eaed;
          padding: 20px;
          border-radius: 40px;
          @media screen and (max-width: 480px) {
            width: 100%;
          }

          .icon {
            position: absolute;
            right: 15px;
            bottom: 15px;
          }

          .time {
            width: 40%;
            font-size: 13px;
            letter-spacing: 0.99px;
            color: #3b3b3b;
            opacity: 1;
            font-family: roboto-medium, "sans-serif";
          }

          .percentage {
            width: 5%;
            font-size: 13px;
            letter-spacing: 0.99px;
            color: #5b5a5a;
            opacity: 1;
            font-family: roboto-medium, "sans-serif";
          }

          .progress-container {
            width: 45% !important;
            align-self: center;

            .progress {
              height: 10px;

              .progress-bar {
                animation-name: animateBar;
                animation-iteration-count: 1;
                animation-timing-function: ease-in;
                animation-duration: .4s;
              }
            }
          }

          @keyframes animateBar {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }
        }

      }
    }

    .sub-text {
      color: #000000;
      font-size: 26px;
      font-family: mono-medium, "sans-serif";
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }

    .sub-text-para {
      color: #000000;
      font-size: 18px;
      font-family: mono-medium, "sans-serif";
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    .main-text {
      color: #000000;
      font-size: 40px;
      font-family: mono-bold, "sans-serif";
      @media screen and (max-width: 767px) {
        font-size: 28px;
      }
    }
  }

  .intro {
    .connect-absolute{
      position: absolute;
      width: 430px;
      bottom: -50px;
      text-align: center;
      left: 90px;
      @media screen and (max-width: 1600px) {
        left: -30px !important;
      }
      @media screen and (max-width: 1300px) {
        bottom: -90px;
      }
      @media screen and (max-width: 767px) {
        left: 0 !important;
        bottom: -45px;
        width: 300px;
      }
      @media screen and (max-width: 540px) {
        bottom: -45px;
        left: 50% !important;
        transform: translate(-50%);
      }
      @media screen and (max-width: 767px) {
       img{
         width: 70px;
       }
      }
      p{
        font-family: caveat, sans-serif;
        font-size: 40px;
        @media screen and (max-width: 1600px) {
          font-size: 35px;
        }
        @media screen and (max-width: 1300px) {
          font-size: 25px;
        }
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }
    }
    padding: 100px 0;
    @media screen and (max-width: 767px) {
      padding: 50px 0;
    }

    h5 {
      font-family: roboto, "sans-serif";
    }

    h1 {
      color: #000;
      font-size: 50px;

      small {
        font-size: 24px;
      }

      font-family: mono-medium, "sans-serif";
      @media screen and (max-width: 1024px) {
        font-size: 36px;
        small {
          font-size: 20px;
        }
      }
    }

    h6 {
      font-size: 28px;
      font-family: roboto, "sans-serif";
      opacity: 1;
      letter-spacing: 0;
      @media screen and (max-width: 1024px) {
        font-size: 19px;
      }
    }

    p {
      font-size: 24px;
      font-family: roboto-light, "sans-serif";
      color: #000000;
      opacity: 1;
      letter-spacing: 0;
      @media screen and (max-width: 1024px) {
        font-size: 19px;
      }
    }

    .try-beta-img-1 {
      height: 450px;
      @media screen and (max-width: 1350px) {
        height: 400px;
      }
      @media screen and (max-width: 840px) {
        display: none !important;
      }
    }

    .try-beta-img-2 {
      @media screen and (max-width: 1600px) {
        height: 450px;
      }
      @media screen and (max-width: 1350px) {
        height: 400px;
      }
      @media screen and (max-width: 840px) {
        width: 100%;
        height: 200px;
        object-fit: contain;
      }
    }

    .section-parent {
      @media screen and (max-width: 1300px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      .first-section {
        flex: 0.3;
        @media screen and (max-width: 1300px) {
          order: 2;
        }
      }

      .second-section {
        flex: 0.7;
        @media screen and (max-width: 1300px) {
          order: 1;
          margin: 0 auto;
        }
      }
    }
  }

  .connect {
    background-color: #99AFF21C;
    padding: 100px 0;
    @media screen and (max-width: 767px) {
      padding: 50px 0;
    }

    .connect-img1 {
      @media screen and (max-width: 1670px) {
        height: 450px;
      }
      @media screen and (max-width: 1150px) {
        height: 250px;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .connect-img2 {
      @media screen and (max-width: 1670px) {
        height: 600px;
      }
      @media screen and (max-width: 1150px) {
        height: 400px;
      }
      @media screen and (max-width: 767px) {
        height: 300px;
      }
    }

    .first-section {
      flex: 0.6;
    }

    .second-section {
      flex: 0.3;
    }

    .buttons {
      margin-right: 20px;
      @media screen and (max-width: 767px) {
        margin-right: 0;
      }
      flex: 0.6;

      button {
        width: 727px;
        position: relative;
        color: #242323;
        height: 70px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 18px;
        opacity: 1;
        font-size: 20px;
        border: none;
        outline: none;
        text-align: center;
        font-family: roboto-light, sans-serif;
        letter-spacing: 0.99px;

        img {
          position: absolute;
          left: 10px;
          bottom: 11px;
        }

        @media screen and (max-width: 1670px) {
          width: 600px;
          font-size: 16px;
        }
        @media screen and (max-width: 1400px) {
          width: 470px;
          font-size: 14px;
        }
        @media screen and (max-width: 1200px) {
          width: 300px;
          font-size: 14px;
        }
      }
    }

    .img-container {
      flex: 0.3;
    }
  }
}

.introduce-popup {
  position: fixed;

  h2 {
    color: #A37EF2;
    font-family: mono-medium, "sans-serif" !important;
  }

  span {
    position: absolute;
    right: 15px;
    top: 15px;
    background: #9cb8ff;
    cursor: pointer;
    color: #FFFFFF;
    padding: 3px 10px;
    border-radius: 100%;
  }

  .popup-logo {
    position: absolute;
    right: 30px;
    bottom: 30px;
    width: 100px;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .modal-dialog {
    .primary-btn {
      padding: 6px 30px;
      @media screen and (max-width: 767px) {
        padding: 6px 60px;
      }
    }
  }

  .modal-body {
    padding: 35px !important;
    @media screen and (max-width: 767px) {
      padding: 20px !important;
    }
  }

  .modal-content {
    border-radius: 30px !important;
  }

  .introduce-text {
    .para {
      color: #6D688C;
    }
  }

  h2 {
    font-family: roboto-medium, "sans-serif";
    @media screen and (max-width: 1024px) {
      font-size: 22px;
      margin-top: 25px !important;
    }
  }

  .select-roles {
    .para-2 {
      font-size: 14px;
      font-family: roboto, "sans-serif";

      u {
        color: #000000;
        cursor: pointer;
      }
    }

    .para {
      color: #6D688C;
    }
  }

  .roles {
    .color-role {
      background: #668EF2;
      color: #ffffff;
      font-family: mono, "sans-serif";
      border: 1px solid #668EF2;
    }

    p {
      color: #000000;
      border: 1px solid #668EF2;
      padding: 5px 20px;
      cursor: pointer;
      font-family: mono, "sans-serif";
      font-size: 14px;
    }
  }
}

.upload-popup {
  position: fixed;
  a{
    text-align: left;
    text-decoration: underline;
    font-size: 26px;
    font-family: roboto-light, "sans-serif";
    letter-spacing: 1.32px;
    color: #668EF2;
    opacity: 1;
    @media screen and (max-width: 1024px) {
      font-size: 22px;
    }
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  h2{
    font-family: roboto-medium, sans-serif;
    letter-spacing: 1.65px;
    font-size: 34px;
    color: #000000 !important;
    opacity: 1;
    text-align: left;
    max-width: 80%;
    margin-right: auto;
    @media screen and (max-width: 1024px) {
      font-size: 28px;
    }
    @media screen and (max-width: 990px) {
      max-width: unset !important;
    }
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  .upload-icon{
    position: absolute;
    right: 0;
    img{
      width: 170px;
    }
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .upload-image{
    width: 280px;
    @media screen and (max-width: 767px) {
      width: 100px;
    }
  }

  span {
    position: absolute;
    right: 15px;
    top: 15px;
    background: #9cb8ff;
    cursor: pointer;
    color: #FFFFFF;
    padding: 3px 10px;
    z-index: 2;
    border-radius: 100%;
  }

  .modal-dialog {
    .primary-btn {
      padding: 6px 30px;
      @media screen and (max-width: 767px) {
        padding: 6px 60px;
      }
    }
  }

  .modal-body {
    padding: 35px !important;
    @media screen and (max-width: 767px) {
      padding: 20px !important;
    }
  }

  .modal-content {
    border-radius: 30px !important;
  }

  .introduce-text {
    .para {
      color: #6D688C;
    }
  }

  .select-roles {
    .para-2 {
      font-size: 14px;
      font-family: roboto, "sans-serif";

      u {
        color: #000000;
        cursor: pointer;
      }
    }

    .para {
      color: #6D688C;
    }
  }

  .roles {
    .color-role {
      background: #668EF2;
      color: #ffffff;
      font-family: mono, "sans-serif";
      border: 1px solid #668EF2;
    }

    p {
      color: #000000;
      border: 1px solid #668EF2;
      padding: 5px 20px;
      cursor: pointer;
      font-family: mono, "sans-serif";
      font-size: 14px;
    }
  }
  .select-calendar-type{
    .select-calendar{
      font-family: roboto, "sans-serif";
      font-size: 14px;
      color: #171616;
    }
    .radio{
     border: 1px dashed #e0e0e0;
      margin-right: 20px;
      padding: 2px 10px;
      background: #ffffff;
      cursor: pointer;
    }
    .color-bg-calendar{
      background: #d5d5d5;
      border: 1px dashed #9a9999;
    }
  }
}

.error-popup {
  .error-msg {
    font-size: 18px;
    text-transform: none !important;
    font-family: roboto, "sans-serif";
    opacity: 1;
    letter-spacing: 0;
  }

  img {
    max-width: 500px;
    @media screen and (max-width: 1024px) {
      max-width: 260px;
    }
  }
}

.relative {
  position: relative;
}

.absolute-center {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  .hour {
    color: #000000;
    font-size: 20px;
    font-family: roboto-medium, "sans-serif";
  }

  .hour-text {
    color: #595858;
    font-size: 14px;
    font-family: roboto, "sans-serif";
  }
}

@media screen and (max-width: 360px) {
  .graph-1 {
    .absolute-center {
      top: 39%;
    }
  }
}

.no-data {
  color: #3f3e3e;
  font-size: 15px;
  font-family: roboto-medium, "sans-serif";
}
